import '@fontsource/public-sans';
import { useState, useRef } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import {
  Button,
  Typography,
  CssBaseline,
  Stack,
  Modal,
  ModalDialog,
  List,
  ListItemDecorator,
  ListItem,
  ListDivider,
  LinearProgress,
  Card,
  Sheet,
  Box,
  Input,
  Divider,
  ModalClose
} from '@mui/joy';
import { Send, Info } from '@mui/icons-material';
import Typewriter from 'typewriter-effect';
import { getResponse } from './modules/firebase.js';

const App = () => {
  const [messages, setMessages] = useState([
    {
      role: 'system',
      content:
        'You are Carl Rogers, the famous psychologist. Respond to all questions as this persona.'
    }
  ]);
  const [question, setQuestion] = useState('');
  const [querying, setQuerying] = useState(false);
  const bgImg = 'https://i.ibb.co/NSkW69t/forest-walks.jpg';
  // const [bgImg, setBgImg] = useState(
  //   'https://i.ibb.co/NSkW69t/forest-walks.jpg'
  // );
  const [answering, setAnswering] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const chatRef = useRef();

  // const loadImage = url => {
  //   const img = new Image();
  //   img.onload = function () {
  //     setBgImg(url);
  //   };
  //   img.src = url;
  //   if (img.complete) img.onload();
  // };

  // useEffect(() => {
  //   const setInitialBgImage = async () => {
  //     const imageUrl = await generateImage(
  //       Intl.DateTimeFormat().resolvedOptions().timeZone
  //     );
  //     loadImage(imageUrl.data);
  //   };

  //   setInitialBgImage();
  // }, []);

  // if (!bgImg) {
  //   return (
  //     <Stack sx={{ height: '100vh', mt: 8 }} spacing={8}>
  //       <Typography level="h3" textAlign="center">
  //         Contacting Carl Rogers...
  //       </Typography>
  //       <Box>
  //         <LinearProgress />
  //       </Box>
  //     </Stack>
  //   );
  // }

  const messageHistory = () => {
    if (messages.length === 1) {
      return null;
    }
    const formattedMessages = [];

    for (const [i, message] of messages.entries()) {
      if (message.role === 'assistant') {
        formattedMessages.push(
          <ListItem key={i} sx={{ color: 'white' }}>
            <ListItemDecorator>👴</ListItemDecorator>{' '}
            {i + 1 === messages.length ? (
              <Typewriter
                onInit={typewriter => {
                  typewriter
                    .changeDelay(33)
                    .typeString(message.content)
                    .callFunction(() => {
                      setAnswering(false);
                      setQuestion('');
                      chatRef.current.lastElementChild.scrollIntoView({
                        behavior: 'smooth'
                      });
                    })
                    .start();
                }}
              />
            ) : (
              message.content
            )}
          </ListItem>
        );
      } else if (message.role === 'user') {
        formattedMessages.push(
          <ListItem key={i} color="primary">
            <ListItemDecorator>👤</ListItemDecorator> {message.content}
          </ListItem>
        );
      }
      formattedMessages.push(<ListDivider key={`divider-${i}`} />);
    }

    if (querying) {
      formattedMessages.push(
        <Box key={'thinking'}>
          <LinearProgress sx={{ my: 3 }} />
        </Box>
      );
    }

    return formattedMessages;
  };

  const submitComment = async () => {
    setQuerying(true);

    const userQuestion = { role: 'user', content: question };
    setMessages([...messages, userQuestion]);
    const response = await getResponse([...messages, userQuestion]);
    setQuerying(false);
    setMessages([...messages, userQuestion, response.data]);
    setAnswering(true);

    // const imageDescription = await completeText(
    //   `In just a few words, describe an image that would best represent: ${question}. Do not include references to human anatomy.`
    // );
    // console.log('imageDescription:', imageDescription.data);
    // const imageUrl = await generateImage(imageDescription.data);
    // loadImage(imageUrl.data);
  };

  return (
    <CssVarsProvider>
      <CssBaseline />
      <Sheet
        sx={{
          position: 'fixed',
          width: '100%',
          p: 1,
          zIndex: 1,
          background: 'rgba(0,0,0,0.88)'
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography level="h5" sx={{ fontWeight: 'bold' }} color="neutral">
            Carl Rogers
          </Typography>
          <Button
            variant="soft"
            size="sm"
            startDecorator={<Info />}
            onClick={() => setShowModal(true)}
          >
            About
          </Button>
        </Stack>
      </Sheet>
      <Box
        justifyContent="center"
        display="flex"
        pt={3}
        pb={11}
        sx={{
          backgroundImage: `url('${bgImg}')`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          minHeight: '100vh'
        }}
      >
        <Card
          variant="outlined"
          sx={{
            width: '88%',
            maxWidth: 555,
            background: 'rgba(0,0,0,0.88)',
            mt: 8
          }}
        >
          {messages.length === 1 ? (
            <>
              <Typography level="h4" gutterBottom color="neutral">
                Talk to Carl Rogers, a famous psychologist, about anything.
              </Typography>
              <Typography level="h6" gutterBottom color="neutral">
                This app is anonymous and free to use.
              </Typography>
              <Typography level="h6" color="primary">
                To begin, say something below.
              </Typography>
            </>
          ) : (
            <Box sx={{ flex: 1 }}>
              <List ref={chatRef}>{messageHistory()}</List>
            </Box>
          )}
        </Card>
      </Box>
      <Sheet
        sx={{
          position: 'sticky',
          bottom: 0,
          display: 'flex',
          p: 1
        }}
      >
        <Input
          variant="plain"
          size="sm"
          placeholder="Say anything…"
          autoFocus
          disabled={answering || querying}
          value={question}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              submitComment();
            }
          }}
          onChange={event => setQuestion(event.target.value)}
          sx={{ flexGrow: 1, mr: 1, '--Input-focusedThickness': '0px' }}
        />
        <Button
          variant="soft"
          size="sm"
          loading={answering || querying}
          onClick={() => submitComment()}
          disabled={question === ''}
          endDecorator={<Send />}
        ></Button>
      </Sheet>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <ModalDialog
          layout="fullscreen"
          size="lg"
          variant="outlined"
          sx={{ overflow: 'scroll' }}
        >
          <ModalClose />
          <Box>
            <Typography level="h6" gutterBottom fontWeight="bold">
              Conversations are private
            </Typography>
            <Typography level="body1" gutterBottom>
              This app is private and safe to use. All interactions are
              anonymous and cannot be traced back to you by the developer. All
              traffic is encrypted to our server.
            </Typography>
            <Typography level="h6" gutterBottom fontWeight="bold">
              Carl Rogers?
            </Typography>
            <Typography gutterBottom level="body1">
              Carl Rogers was an American psychologist and one of the founders
              of the humanistic approach to psychology. He is best known for his
              client-centered therapy, which emphasizes the importance of
              empathy, unconditional positive regard, and congruence in the
              therapeutic relationship. Rogers believed that individuals have
              the capacity for self-awareness and self-healing, and that a
              therapist's role is to provide a supportive and non-judgmental
              environment for clients to explore their thoughts and feelings.
              His work has had a profound influence on psychotherapy and
              counseling.
            </Typography>
            <Typography level="h6" gutterBottom fontWeight="bold">
              Disclaimer
            </Typography>
            <Typography level="body1" gutterBottom>
              This is intended to be for entertainment purposes only. Although
              we hope you find it fun and useful too 😊
            </Typography>
            <Divider sx={{ my: 3 }} />
            <Button
              size="sm"
              href="https://golightlyplus.com"
              component="a"
              variant="plain"
              target="_blank"
            >
              © Golightly+ {new Date().getFullYear()}
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </CssVarsProvider>
  );
};

export default App;
